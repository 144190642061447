import React from "react";
import { ProxyItemProps } from "../Interfaces"


const ProxyRow: React.FC<ProxyItemProps> = ({ rowKey, proxyItem, isChecked, handleCheckboxChange, handleDeleteProxy, handleEditProxy }) => {
    const proxyUrl = `${proxyItem.protocol}://${proxyItem.ipv4}:${proxyItem.port}${proxyItem.username ? `:${proxyItem.username}` : ""}${proxyItem.password ? `:${proxyItem.password}` : ""}`;
    const ACTIVE = "Active";

    return (
        <tr className={`el_tr_${rowKey}}`}>
            <td>
                <input type="checkbox" style={{ marginRight: 10 }} value={proxyItem.id} checked={isChecked} onChange={() => handleCheckboxChange(proxyItem.id)} />
                <span style={{ color: "rgb(255, 96, 96)" }}>{proxyItem.desc || ""}</span>
            </td>
            <td>{proxyUrl}</td>
            <td style={{color: proxyItem.status === ACTIVE ? 'green' : 'red'}}>{proxyItem.status}</td>
            <td>
                <button className="btn-edit" onClick={() => handleEditProxy(proxyItem.id, proxyUrl)}>Edit</button>
                <button className="btn-delete" onClick={() => handleDeleteProxy(proxyItem.id)}>Delete</button>
            </td>
        </tr>
    );
};

export default ProxyRow;
